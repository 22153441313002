<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Index msg="Welcome to Your Vue.js App"/>
    <!-- <Test /> -->
  </div>
</template>

<script>
import Index from './views/index.vue'
// import Test from './components/test.vue'

export default {
  name: 'App',
  components: {
    Index
    // Test
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #fff;
  display: flex;
}
#app{
  width: 100%;
  height: 100vh;
}
</style>
