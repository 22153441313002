//导入模块
import Vue from 'vue';
import VueRouter from 'vue-router';
import Introduce from '@/components/Introduce'
import History from '@/components/History'
import Member from '@/components/Member'
import Officer from '@/components/Officer'
import Online from '@/components/Online'
import Offline from '@/components/Offline'
import Contact from '@/components/Contact'
import Recruit from '@/components/Recruit'
import EmptyView from '@/components/EmptyView'
//注册路由组件
Vue.use(VueRouter);
//编写路由规则
const routes = [
  {
    path: "/",
    name: 'Home',
    component: Introduce
  },
  {
    path: "/history",
    name: 'History',
    component: History
  },
  {
    path: "/member",
    name: 'Member',
    component: Member
  },
  {
    path: "/officer",
    name: 'Officer',
    component: Officer
  },
  {
    path: "/activity",
    name: 'Activity',
    component: EmptyView,
    children: [
      {
        path: "onLine",
        name: 'ActivityOnLine',
        component: Online
      },
      {
        path: "offLine",
        name: 'ActivityOffLine',
        component: Offline
      }
    ]
  },
  {
    path: "/contact",
    name: 'Contact',
    component: Contact,
  },
  {
    path: "/recruit",
    name: 'Recruit',
    component: Recruit,
  }
]
//创建路由
const router = new VueRouter({
  mode:'history',
  routes
})
//导出
export default router;
