<template>
    <div class="contact">
      <h1>{{ $t('nav.tab1_sub3') }}</h1>
<!--      <p>{{ $t('home.member.desc') }}</p>-->
      <div style="padding: 0 10px">
        <div class="member-item" v-for="member in $t('company.member.memberList')" :key="member.introduce">
          <h3>{{ member.introduce }}</h3>
          <img class="member-avatar" :src="getImg(member.img)" alt="">
          <p v-html="member.des"></p>
        </div>
        <div class="member-des" v-html="$t('company.member.memberDes')"></div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "ContactTab",
    methods: {
      getImg(img){
        return require(`@/assets/member/${img}.png`)
      }
    }
  };
  </script>
<style lang="scss">
.member-item{
  margin-top: 60px;
}
.member-avatar{
  width: 40vw;
}
.member-des{
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
}
</style>
