<template>
    <div class="contact">
      <h1>{{ $t('nav.tab1_sub4') }}</h1>
      <h4 v-html="$t('company.officer.officerDes')"></h4>

      <div style="padding: 0 10px">
        <div class="member-item" v-for="member in $t('company.officer.officerList')" :key="member.introduce">
          <h3>{{ member.introduce }}</h3>
          <img class="member-avatar" :src="getImg(member.img)" alt="">
          <p v-html="member.des"></p>
        </div>
<!--        <div class="member-des" v-html="$t('company.officer.officerDes')"></div>-->
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "ContactTab",
    methods: {
      getImg(img){
        return require(`@/assets/member/${img}.png`)
      }
    }
  };
  </script>
