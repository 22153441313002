<template>
  <div class="activity">
    <el-collapse v-model="activeNames">
      <el-collapse-item :key="item.title" v-for="item in list.filter(item => item.year >= 2022)" :title="`${item.year}-${item.season}-${item.title}`" :name="item.title">
        <div>{{ item.content }}</div>
        <img class="img" v-if="item.imgUrl" :src="getImg(item.imgUrl)" alt="">
        <p class="location" v-if="item.location">{{ $t('activity.location')}}:{{ item.location }}</p>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "ActivityPage",
  props: {
    list: [Array, String]
  },
  data(){
    return{
      activeNames: '1'
    }
  },
  mounted() {
    console.log(this.list)
  },
  methods: {
    getImg(url){
      return require(`@/assets/offLineActivity/${url}`)
    }
  }
}
</script>

<style scoped lang="scss">
.activity{
  padding: 20px;
  :deep(.el-collapse){
    .el-collapse-item__header{
      padding-left: 20px;
    }
    .el-collapse-item__content{
      padding-left: 20px;
    }
  }

  .img{
    width: 40vw;
  }
  .location{
    text-align: right;
    padding-right: 30px;
  }
}
</style>
