<template>
  <div>
    <div class="contact">
      <h1>{{ $t('nav.tab2_sub2') }}</h1>
      <h4>{{ $t('activity.offLineActivityDes') }}</h4>
      <h5>{{ $t('activity.offLineActivitySubDes') }}</h5>
    </div>
    <Activity :list="$t('activity.offLineActivity')"></Activity>
  </div>
</template>
<script>
import Activity from "@/components/Activity";
export default {
  name: "OffLine",
  components: {Activity},
};
</script>
<style scoped>
.contact{
  padding: 0 40px;
}
</style>
