<template>
  <div class="contact-us">
    <div class="contact-us-wrapper">
      <img :src="contactUs" alt="">
      <div class="contact">
        <h1>{{ $t('nav.tab4') }}</h1>
        <p>{{ $t('home.contact.desc') }}</p>
        <div class="contact-info">
          <div class="contact-item">
            <i class="fas fa-envelope"></i>
            <p>example@gmail.com</p>
          </div>
          <div class="contact-item">
            <i class="fab fa-linkedin"></i>
            <p>linkedin.com/in/yourprofile</p>
          </div>
          <div class="contact-item">
            <i class="fab fa-github"></i>
            <p>github.com/yourusername</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactTab",
  data(){
    return {
      contactUs: require('@/assets/contactUs.jpg')
    }
  }
};
</script>
<style scoped lang="scss">
.contact-us{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  .contact-us-wrapper{
    display: flex;
    .contact{
      background-color: #409EFF;
      color: #fff;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
