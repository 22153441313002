<template>
<div class="recruit">
  <img :src="joinUsImg" alt="">
  <h2>{{ $t('aboutUs.title') }}</h2>
  <p>{{ $t('aboutUs.content') }}</p>

  <h2>{{ $t('lookingFor.title') }}</h2>
  <p>{{ $t('lookingFor.content') }}</p>

  <div class="jobs">
    <div class="job-list" :key="index" v-for="(item, index) in $t('lookingFor.jobList')">
      <h1 class="job-name">{{ item.jobName }}</h1>
      <h4>{{ $t('lookingFor.requirements') }}:</h4>
      <p class="recruit-des">{{ item.requirements }}</p>
      <h4>{{ $t('lookingFor.responsibilities') }}:</h4>
      <p class="recruit-des">{{ item.responsibilities }}</p>
    </div>

    <h2>{{ $t('lookingFor.whatWeOffer.title') }}</h2>
    <p v-html="$t('lookingFor.whatWeOffer.content')"></p>

    <h2>{{ $t('lookingFor.howToApply.title') }}</h2>
    <p v-html="$t('lookingFor.howToApply.content')"></p>
  </div>
</div>
</template>

<script>
export default {
  name: "RecruitPage",
  data(){
    return{
      joinUsImg: require('@/assets/joinUs.jpg')
    }
  }
}
</script>

<style scoped lang="scss">
.recruit{
  img{
    width: 100vw;
  }
  .jobs{
    padding: 0 20px;
  }

  .job-list{
    text-align: left;
    padding: 20px;
    .job-name{
      margin-bottom: 10px;
    }
    h1,h2,h3,h4,h5,p{
      margin: 0;
    }
    .recruit-des{
      padding-left: 10px;
    }
  }
}
</style>
