<template>
    <div class="contact">
      <div class="banner">
        <!-- <img src="../assets/banner2.jpeg" alt="Banner" class="banner" /> -->
        <!-- <h1>{{ currentTabName }}</h1> -->
        <el-carousel height="700px">
          <el-carousel-item v-for="image in images" :key="image.id">
            <img :src="image.url" alt="Banner" class="banner" />
          </el-carousel-item>
        </el-carousel>

      </div>
      <h1>{{ $t('nav.tab1_sub1') }}</h1>
      <h2 v-html="$t('company.introduction.title')"></h2>
      <img :src="logo" alt="">
      <p v-html="$t('company.introduction.desc')"></p>
    </div>
  </template>
  <script>
  export default {
    name: "ContactTab",
    data(){
      return{
        images: [
          { id: '1', url: require('../assets/pic1.jpeg') },
          { id: '2', url: require('../assets/pic2.jpeg') },
          { id: '3', url: require('../assets/pic3.jpeg') },
        ],
        logo: require('@/assets/introduce/logo.png')
      }
    }
  };
  </script>
