<template>
    <div>
      <div class="contact">
        <h1>{{ $t('nav.tab2_sub1') }}</h1>
        <h4>{{ $t('activity.onLineActivityDes') }}</h4>
        <h5>{{ $t('activity.onLineActivitySubDes') }}</h5>
      </div>
      <Activity :list="$t('activity.onLineActivity')"></Activity>
    </div>
  </template>
  <script>
  import Activity from "@/components/Activity";
  export default {
    name: "ContactTab",
    components: {Activity},
  };
  </script>
<style scoped>
.contact{
  padding: 0 40px;
}
</style>
